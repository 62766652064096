import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './pricing.css'

const Pricing = (props) => {
  return (
    <div className="pricing-container">
      <Helmet>
        <title>Pricing - Seed Solutions</title>
        <meta
          name="description"
          content="Connecting the world of Cannabis; One Tracking System, One WorkFlow, One Industry."
        />
        <meta property="og:title" content="Pricing - Seed Solutions" />
        <meta
          property="og:description"
          content="Connecting the world of cannabis; One Tracking System, One WorkFlow, One Industry."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name6"></Header>
      <Footer></Footer>
    </div>
  )
}

export default Pricing
