import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './login.css'

const Login = (props) => {
  return (
    <div className="login-container">
      <Helmet>
        <title>Login - Seed Solutions</title>
        <meta
          name="description"
          content="Connecting the world of Cannabis; One Tracking System, One WorkFlow, One Industry."
        />
        <meta property="og:title" content="Login - Seed Solutions" />
        <meta
          property="og:description"
          content="Connecting the world of cannabis; One Tracking System, One WorkFlow, One Industry."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name4"></Header>
      <Footer></Footer>
    </div>
  )
}

export default Login
