import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Card from '../components/card'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Seed Solutions</title>
        <meta
          name="description"
          content="Connecting the world of Cannabis; One Tracking System, One WorkFlow, One Industry."
        />
        <meta property="og:title" content="Seed Solutions" />
        <meta
          property="og:description"
          content="Connecting the world of cannabis; One Tracking System, One WorkFlow, One Industry."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name"></Header>
      <div className="home-hero section-container">
        <div className="home-max-width max-width">
          <div className="home-content">
            <span className="home-subtitle beforeHeading">
              Seed solutions seed-to-sale Cannabis tracking system
            </span>
            <h1 className="home-title">
              <span className="home-text">
                Connecting the World of Cannabis
              </span>
              <br></br>
              <br></br>
              <span className="home-text03">One</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text05">Tracking System,</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span className="home-text08">One</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text10">Workflow,</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span className="home-text13">One</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text15">Industry</span>
            </h1>
            <span className="home-description">
              Seed Solutions is a technology company that strengthens
              business/client connections through communication and building
              mutual trust; while increasing business efficiency with a true
              seed to sale platform.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <div className="home-container1">
              <button className="home-button button button-gradient">
                Get started
              </button>
              <Link
                to="/login"
                className="home-navlink button button-transparent"
              >
                Log in
              </Link>
              <Link to="/get-started" className="home-navlink1 button">
                Get started
              </Link>
            </div>
          </div>
          <div className="home-image">
            <img
              alt="pastedImage"
              src="/external/pastedimage-x80k-200w.png"
              className="home-pasted-image"
            />
            <img
              alt="image"
              src="/kisspng-kush-cannabis-sativa-cannabis-ruderalis-leaf-cannabis-5ab4571db128f0.9803092115217682217257-400w.png"
              className="home-graphic-top"
            />
            <img alt="image" src="/hero1-600h.png" className="home-image1" />
          </div>
        </div>
      </div>
      <div className="home-section section-container">
        <span className="home-text16 beforeHeading">Our Approach</span>
        <h1 className="home-text17">
          <span>The One Stop-Shop Cannabis Software Solution for Everyone</span>
          <span></span>
        </h1>
        <img
          alt="pastedImage"
          src="/retail-productshot-1200h.png"
          loading="lazy"
          className="home-image2"
        />
        <div className="home-max-width1 max-width">
          <span className="home-text20">
            <span>
              Seed Solutions is the technology company that is changing the way
              the cannabis industry does business. We understand the challenges
              faced by cannabis licensees and regulators, including the burden
              of using multiple, non-integrated systems and the difficulties of
              tracking &amp; documenting every step of the seed-to-sale process.
              That&apos;s why we have developed a comprehensive platform that
              streamlines workflow and simplifies compliance.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <br></br>
            <span>
              Our platform includes everything you need to manage your cannabis
              business, from seed to sale. It includes a user-friendly system
              that integrates with existing cannabis software to eliminate the
              need for dual data entry, all while offering custom dashboard
              solutions for each licensee type, including: cultivators,
              processors, manufacturers, testing laboratories,
              transportation/distribution/wholesale companies, and
              dispensaries. Our seed-to-sale tracking system is designed to meet
              the specific requirements of each state, so you can be confident
              that you are meeting all of the necessary regulations.
            </span>
            <br></br>
            <br></br>
            <span>
              {' '}
              Contact us today to learn more about how Seed Solutions can
              benefit your business.
            </span>
            <br></br>
          </span>
        </div>
        <Link
          to="/get-started"
          className="home-navlink2 button-secondary button bg-transparent"
        >
          Get Started
        </Link>
      </div>
      <div className="home-section1 section-container">
        <div className="home-max-width2 max-width">
          <div className="home-image3">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1546030001-8bd8ac78d21c?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDYzfHxjYW5uYWJpc3xlbnwwfHx8fDE2NzgzMDM5MTM&amp;ixlib=rb-4.0.3&amp;w=1200"
              className="home-hero-image"
            />
          </div>
          <div className="home-content1">
            <span className="home-text29 beforeHeading">Features</span>
            <h1 className="home-text30">A Comprehensive Solution</h1>
            <div className="home-container2">
              <div className="home-step">
                <div className="home-number">
                  <span className="home-text31">1</span>
                </div>
                <div className="home-container3">
                  <span className="home-title1">
                    Custom Dashboard Solutions
                  </span>
                  <span className="home-text32">
                    Seed Solutions offers custom dashboard solutions for each
                    licensee type, including cultivators, processors,
                    manufacturers, testing laboratories,
                    transportation/distribution/wholesale companies, and
                    dispensaries. This means that you can access the specific
                    tools and features that are most relevant to your business,
                    all in one place.
                  </span>
                </div>
              </div>
              <div className="home-step1">
                <div className="home-number1">
                  <span className="home-text33">2</span>
                </div>
                <div className="home-container4">
                  <span className="home-title2">User Friendly</span>
                  <span className="home-text34">
                    Seed Solutions is designed to be easy to use and intuitive,
                    with a clear and logical layout that makes it simple to
                    navigate.
                  </span>
                </div>
              </div>
              <div className="home-step2">
                <div className="home-number2">
                  <span className="home-text35">3</span>
                </div>
                <div className="home-container5">
                  <span className="home-title3">Compliant</span>
                  <span className="home-text36">
                    Seed Solutions is designed to meet the specific requirements
                    of each state, so you can be confident that you are meeting
                    all of the necessary regulations.
                  </span>
                </div>
              </div>
              <div className="home-step3">
                <div className="home-number3">
                  <span className="home-text37">4</span>
                </div>
                <div className="home-container6">
                  <span className="home-title4">Transparent</span>
                  <span className="home-text38">
                    Seed Solutions provides transparency for consumers by
                    connecting them to retailers and dispensaries and giving
                    them access to information about the products they are
                    purchasing. This helps to protect the end consumer and
                    ensure that they are making informed decisions.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section2 section-container">
        <span className="home-text39 beforeHeading">get started</span>
        <h1 className="home-text40">Select your license type to begin.</h1>
        <div className="home-max-width3 max-width">
          <div className="home-cards-container">
            <Link to="/get-started" className="home-navlink3">
              <Card
                rootClassName="card-root-class-name"
                className="home-component1"
              ></Card>
            </Link>
            <Link to="/get-started" className="home-navlink4">
              <Card
                text="Laboratory"
                text1="LIMS dashboard designed to automate workflows, integrate instruments, and manage samples"
                rootClassName="card-root-class-name2"
                className="home-component2"
              ></Card>
            </Link>
            <Link to="/get-started" className="home-navlink5">
              <Card
                text="Dispensary"
                text1="POS dashboard to provide dispensaries with the means to sell their products and remain compliant"
                rootClassName="card-root-class-name1"
                className="home-component3"
              ></Card>
            </Link>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Home
