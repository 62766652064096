import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './get-started.css'

const GetStarted = (props) => {
  return (
    <div className="get-started-container">
      <Helmet>
        <title>Get-Started - Seed Solutions</title>
        <meta
          name="description"
          content="Connecting the world of Cannabis; One Tracking System, One WorkFlow, One Industry."
        />
        <meta property="og:title" content="Get-Started - Seed Solutions" />
        <meta
          property="og:description"
          content="Connecting the world of cannabis; One Tracking System, One WorkFlow, One Industry."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name1"></Header>
      <Footer></Footer>
    </div>
  )
}

export default GetStarted
