import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Pricing from './views/pricing'
import OurApproach from './views/our-approach'
import Login from './views/login'
import Blog from './views/blog'
import GetStarted from './views/get-started'
import Home from './views/home'
import Features from './views/features'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Pricing} exact path="/pricing" />
        <Route component={OurApproach} exact path="/our-approach" />
        <Route component={Login} exact path="/login" />
        <Route component={Blog} exact path="/blog" />
        <Route component={GetStarted} exact path="/get-started" />
        <Route component={Home} exact path="/" />
        <Route component={Features} exact path="/features" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
