import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './blog.css'

const Blog = (props) => {
  return (
    <div className="blog-container">
      <Helmet>
        <title>Blog - Seed Solutions</title>
        <meta
          name="description"
          content="Connecting the world of Cannabis; One Tracking System, One WorkFlow, One Industry."
        />
        <meta property="og:title" content="Blog - Seed Solutions" />
        <meta
          property="og:description"
          content="Connecting the world of cannabis; One Tracking System, One WorkFlow, One Industry."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name2"></Header>
      <Footer></Footer>
    </div>
  )
}

export default Blog
