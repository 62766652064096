import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './our-approach.css'

const OurApproach = (props) => {
  return (
    <div className="our-approach-container">
      <Helmet>
        <title>Our-Approach - Seed Solutions</title>
        <meta
          name="description"
          content="Connecting the world of Cannabis; One Tracking System, One WorkFlow, One Industry."
        />
        <meta property="og:title" content="Our-Approach - Seed Solutions" />
        <meta
          property="og:description"
          content="Connecting the world of cannabis; One Tracking System, One WorkFlow, One Industry."
        />
      </Helmet>
      <Header rootClassName="header-root-class-name5"></Header>
      <Footer></Footer>
    </div>
  )
}

export default OurApproach
